import React from "react"
import LayoutChild from "../components/layoutChild"
import SEO from "../components/seo"
import { color, fontSize, space } from '../components/constants'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Img from "gatsby-image";


const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 0px 36px 72px;

    @media (max-width: 1024px){
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }

    @media (max-width: 640px){
        grid-template-columns: 100% ;
        ${'' /* grid-template-rows: 100%; */}
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }
`
/* Text Group */
const CellTitleGroup = styled.div`
    width: 100%;
    @media (max-width: 640px){
        ${'' /* grid-row: 2; */}
        width: 100%;
        margin-top: 8px;
    }

`
const Title = styled.div`
    color: white;
    font-size: ${fontSize[3]};
    font-weight: 200;
    margin-top: ${space[4]+ "px"};
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    text-rendering: optimizeLegibility;

    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }

`
const Text = styled.p`
    margin-top: ${space[2]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: ${color.gray2};
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
const Subhead = styled.p`
    margin-top: ${space[4]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: white;
    margin-bottom: 0px;
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
/* Image styles */

const ImgSingle = styled(Img)`
    border-radius: 24px;
`
const ImgDouble = styled(Img)`
    border-radius: 24px;
    grid-column: 1 / span 2;
    border: 1px solid ${color.gray6};
    @media (max-width: 640px){
        grid-column: 1 / span 1;
    }
`

export default ({ data }) => (
  <LayoutChild>
    <SEO 
      title="Scroll Reader App"
      description="A companion app to the Scroll experience"
      image="../images/card.jpg"
    />
    <PageLayout>
        <CellTitleGroup>
            <Title >DAT Design Theme&nbsp;&&nbsp;System</Title>
            <Subhead>ROLE: Program & design lead</Subhead>
            <Text>With DAT's rebrand in 2020, it was time to refresh the product line with a new visual and interactive language that would embrace and embody a new identity. Starting with the brand color and typeface reference, a digital color palette and type system were&nbsp;created. </Text>
            <Text> Building off this foundation, a new visual design theme was created to represent the brand attributes throughout DAT’s products. This visual language was then broken down to primitives to create DAT’s design system of pallets, typography, icons and variant&nbsp;components. </Text>
            <Subhead>Program management, concept, visual design, design system,&nbsp;documentation.</Subhead>
            </CellTitleGroup>
          <ImgDouble 
                fluid={data.image1.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image2.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image3.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image4.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image5.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image6.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image7.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image8.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
          <ImgDouble 
                fluid={data.image9.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />                                            
        </PageLayout>
  </LayoutChild>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "dat-style/dat-style1.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "dat-style/dat-style2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image3: file(relativePath: { eq: "dat-style/dat-style3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image4: file(relativePath: { eq: "dat-style/dat-style4.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image5: file(relativePath: { eq: "dat-style/dat-style5.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image6: file(relativePath: { eq: "dat-style/dat-style6.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image7: file(relativePath: { eq: "dat-style/dat-style7.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image8: file(relativePath: { eq: "dat-style/dat-style8.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    image9: file(relativePath: { eq: "dat-style/dat-style9.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`